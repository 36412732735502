.ready-main {
    .heading {
        font-size: 60px;
        color: #483a46;
        font-family: "commonsBold";
        text-align: center;
        margin-top: 52px;
        margin-bottom: 60px;
        padding: 0 16px;
        @media (max-width: 576px) {
            margin-top: 32px;
            margin-bottom: 40px;
            font-size: 36px;
        }
    }
    .test-data {
        position: relative;
        text-align: center;
        img {
            @media (max-width: 640px) {
                width: 90%;
            }
        }
        p {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 40px;
            font-family: "commonsBold";
            color: #fff;
            margin-bottom: 0;
            max-width: 200px;
            line-height: 50px;
            @media (max-width: 576px) {
                font-size: 26px;
                line-height: 32px;
            }
        }
    }
}
