.welcome-main {
    max-width: 820px;
    margin: auto;
    background-color: #f2f1f6;
    .welcome-data {
        background-color: #a5d4ed;
        .main-patient {
            position: relative;
            padding: 18px 32px;
            @media (max-width: 576px) {
                padding: 18px 10px;
                display: flex;
                justify-content: space-between;
            }
            .new-btn {
                font-size: 20px;
                font-family: "commonsBold";
                border-radius: 30px;
                color: #483a46;
                position: absolute;
                top: 50%;
                right: 29px;
                transform: translateY(-50%);
                background-color: transparent;
            }
            .logo {
                text-align: center;
                img {
                    width: 172px;
                    @media (max-width: 576px) {
                        width: 152px;
                    }
                }
            }
        }
        h1 {
            color: #483a46;
            font-size: 60px;
            font-family: "commonsBold";
            max-width: 280px;
            margin-left: 45px;
            padding-bottom: 24px;
            margin-top: 250px;
            @media (max-width: 576px) {
                margin-top: 180px;
                font-size: 46px;
                margin-left: 25px;
            }
            @media (max-width: 425px) {
                margin-top: 130px;
                max-width: 180px;
                font-size: 36px;
            }
        }
    }
    .patient-card {
        width: 100%;
        padding: 44px 50px 120px 50px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 768px) {
            padding: 44px 25px 120px 25px;
        }
        @media (max-width: 576px) {
            display: block;
            padding: 44px 14px 50px 14px;
        }
        .patient-data {
            position: relative;
            width: 50%;
            @media (max-width: 576px) {
                width: 100%;
            }
            .patient-list {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                position: relative;
                z-index: 1;
                .list-data {
                    background: #8e576f;
                    border-radius: 10px 10px 0px 0px;
                    padding: 20px 18px;
                    .avtar {
                        text-align: end;
                        img {
                            width: 40px;
                            height: 40px;
                            &:nth-child(1) {
                                position: relative;
                                z-index: 3;
                            }
                            &:nth-child(2) {
                                margin-left: -10px;
                                z-index: 2;
                                position: relative;
                            }
                            &:nth-child(3) {
                                margin-left: -10px;
                                z-index: 1;
                                position: relative;
                            }
                        }
                    }
                    h1 {
                        color: #fff;
                        font-size: 30px;
                        font-family: "commonsBold";
                        margin-bottom: 0;
                        margin-top: 92px;
                        max-width: 140px;
                        line-height: 30px;
                        @media (max-width: 768px) {
                            font-size: 24px;
                            max-width: 110px;
                        }
                        @media (max-width: 576px) {
                            margin-top: 62px;
                        }
                    }
                }
                button {
                    color: #483a46;
                    font-size: 17px;
                    font-family: "commonsBold";
                    background: #f2f1f6;
                    border: 1px solid #d9d9d9;
                    border-radius: 30px;
                    width: 177px;
                    height: 51px;
                    margin: 20px 0;
                }
            }
            .patients {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                height: 323px;
                position: absolute;
                width: 96%;
                left: 6px;
                top: 13px;
                @media (max-width: 576px) {
                    height: 292px;
                }
            }
        }
        .dashboard {
            @media (max-width: 576px) {
                margin-top: 40px;
            }
            .list-data {
                background: #483a46 !important;
                h1 {
                    margin-top: 62px !important;
                    @media (max-width: 576px) {
                        margin-top: 33px !important;
                    }
                }
            }
            button {
                width: 225px !important;
            }
        }
    }
}
