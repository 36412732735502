.new-patient {
    max-width: 820px;
    margin: auto;
    background-color: #483a46;
    padding: 50px;
    @media (max-width: 576px) {
        padding: 20px;
    }
    .bg-lights {
        background-color: #fff;
        border-radius: 20px;
        min-height: 800px;
        @media (max-width: 576px) {
            min-height: 660px;
        }
        .main-patient {
            position: relative;
            padding: 18px 32px;
            border-bottom: 1px solid #d9d9d9;
            @media (max-width: 576px) {
                padding: 18px 10px;
            }
            .patient-btn {
                font-size: 17px;
                font-family: "commonsBold";
                width: 130px;
                height: 51px;
                background: #f2f1f6;
                border: 1px solid #d9d9d9;
                border-radius: 30px;
                color: #483a46;
                @media (max-width: 576px) {
                    width: 90px;
                    height: 44px;
                }
            }
            .logo {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                top: 50%;
                @media (max-width: 576px) {
                    left: initial;
                    right: -80px;
                }
                img {
                    width: 172px;
                    @media (max-width: 576px) {
                        width: 152px;
                    }
                }
            }
        }
        .new-patient-data {
            background-color: #f2f1f6;
            padding: 54px 12px 0;
            border-radius: 0 0 20px 20px;
            min-height: 800px;
            @media (max-width: 576px) {
                min-height: 660px;
            }
            h1 {
                color: #483a46;
                font-family: "commonsBold";
                text-align: center;
            }
            .form-main {
                margin-top: 60px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                .select-data {
                    max-width: 375px;
                    width: 375px;
                    margin: auto;
                    position: relative;
                    text-align: center;
                    @media (max-width: 576px) {
                        width: 100%;
                        max-width: 100%;
                    }
                    svg {
                        position: absolute;
                        font-size: 24px;
                        right: 22px;
                        top: 18px;
                        color: #8e576f;
                    }
                }
                .form-data {
                    background: #ffffff;
                    max-width: 375px;
                    width: 375px;
                    margin: auto;
                    border: 1px solid #d9d9d9;
                    border-radius: 10px;
                    padding: 4px 16px;
                    outline: none;
                    @media (max-width: 576px) {
                        width: 100%;
                        max-width: 100%;
                    }
                    label {
                        width: 100%;
                        font-family: "commonsBold";
                        color: #483a46;
                        font-size: 12px;
                    }
                    input {
                        border: 0;
                        outline: none;
                        width: 100%;
                        font-family: "commonsRegular";
                        margin-top: 4px;
                        height: 20px;
                    }
                }
                select {
                    height: 58px;
                    font-size: 16px;
                    color: #483a46;
                    font-family: "commonsBold";
                }
                .new-btn {
                    background-color: #8e576f;
                    width: 154px;
                    height: 51px;
                    border-radius: 30px;
                    color: #ffffff;
                    font-family: "commonsBold";
                    font-size: 18px;
                    margin-top: 15px;
                    margin-bottom: 96px;
                    @media (max-width: 576px) {
                        position: initial;
                        transform: none;
                        width: 134px;
                        height: 43px;
                        font-size: 14px;
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}
