.main-header {
    background-color: #f2f1f6;
    position: relative;
    padding: 18px 29px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    @media (max-width: 576px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 2px;
    }
    .home-btn {
        background-color: transparent;
        border: 0;
        color: #483a46;
        font-family: "commonsBold";
        font-size: 20px;
        display: flex;
        align-items: center;
        @media (max-width: 576px) {
            font-size: 14px;
        }
        svg {
            font-size: 30px;
            @media (max-width: 576px) {
                font-size: 24px;
            }
        }
    }
    .logo {
        width: 172px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 576px) {
            width: 122px;
            left: 48%;
        }
    }
    .new-btn {
        background-color: #8e576f;
        width: 174px;
        height: 51px;
        border-radius: 30px;
        color: #ffffff;
        font-family: "commonsBold";
        font-size: 18px;
        @media (max-width: 576px) {
            position: initial;
            transform: none;
            width: 124px;
            height: 43px;
            font-size: 14px;
            margin-right: 16px;
        }
        @media (max-width: 425px) {
            width: 80px;
            line-height: 16px;
        }
    }
}
