.tinetti-gait {
    padding: 0 85px;
    margin-top: 100px;
    .add_data {
        margin-top: 14px;
        background-color: #f2f1f6;
        padding: 18px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 89px;
        .add_image {
            display: flex;
            align-items: center;
            gap: 13px;
            h4 {
                font-size: 24px;
                font-family: "commonsBold";
                color: #483a46;
            }
        }
        .start_theme {
            width: 127px;
            height: 51px;
            border-radius: 30px;
            color: #ffffff;
            border: none;
            font-size: 17px;
            font-weight: 700;
            font-family: "commonsBold";
            background-color: #3d963e;
        }

        .remove_theme {
            width: 114px;
            height: 51px;
            background: #f2f1f6;
            border: 1px solid #d9d9d9 !important;
            border-radius: 30px;
            color: #483a46;
            font-size: 17px;
            font-weight: 700;
            font-family: "commonsBold";
        }
    }
}
