.questionaire-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
    border-bottom: 1px solid #d9d9d9;
    @media (max-width: 576px) {
        padding: 18px 10px;
    }
    button {
        background: #f2f1f6;
        border: 1px solid #d9d9d9;
        border-radius: 30px;
        width: 130px;
        height: 51px;
        color: #483a46;
        font-family: "commonsBold";
        font-size: 17px;
        @media (max-width: 576px) {
            width: 80px;
            height: 44px;
            font-size: 15px;
        }
        @media (max-width: 425px) {
            width: 75px;
            height: 44px;
            font-size: 15px;
        }
    }
    .logo {
        width: 172px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 576px) {
            width: 112px;
            left: 50%;
        }
    }
}
