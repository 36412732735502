.patient-main {
    max-width: 820px;
    margin: auto;
    background-color: #f2f1f6;
    .patient-list {
        padding: 0 50px 50px;
        @media (max-width: 576px) {
            padding: 0 16px 20px;
        }
        .patient-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 44px;
            @media (max-width: 576px) {
                display: block;
                margin-top: 34px;
            }
            h1 {
                color: #483a46;
                font-family: "commonsBold";
                font-size: 40px;
                @media (max-width: 576px) {
                    font-size: 22px;
                }
            }
            .search-data {
                position: relative;
                @media (max-width: 576px) {
                    margin-top: 20px;
                }
                img {
                    position: absolute;
                    left: 17px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                input {
                    width: 190px;
                    height: 50px;
                    background: #f2f1f6;
                    border: 1px solid #dad2d2;
                    border-radius: 30px;
                    outline: none;
                    color: #483a46;
                    font-family: "commonsBold";
                    padding-left: 42px;
                    &::placeholder {
                        opacity: 1;
                        color: #483a46;
                    }
                    @media (max-width: 576px) {
                        width: 100%;
                    }
                }
            }
        }
        .main-data {
            margin-top: 51px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            width: 100%;
            @media (max-width: 576px) {
                margin-top: 30px;
            }
            .patient-profile {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 21px 25px;
                width: 100%;
                @media (max-width: 576px) {
                    padding: 8px 10px;
                }
                .patient-data {
                    display: flex;
                    align-items: center;
                    img {
                        width: auto;
                        @media (max-width: 576px) {
                            width: 46px;
                        }
                    }
                    h1 {
                        color: #483a46;
                        font-size: 24px;
                        font-family: "commonsBold";
                        margin-left: 25px;
                        margin-bottom: 0;
                        @media (max-width: 576px) {
                            font-size: 16px;
                            margin-left: 12px;
                        }
                    }
                }
                button {
                    background: #f2f1f6;
                    color: #483a46;
                    font-size: 18px;
                    font-family: "commonsBold";
                    border: 1px solid #dad2d2;
                    border-radius: 30px;
                    width: 140px;
                    height: 50px;
                    @media (max-width: 576px) {
                        width: 100px;
                        height: 36px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
