.test-main {
    .note-main {
        color: #483a46;
        margin-top: 116px;
        padding-bottom: 113px;
        @media (max-width: 576px) {
            margin-top: 60px;
            padding: 0 16px 20px;
        }
        h2 {
            font-size: 24px;
            text-align: center;
            font-family: "commonsBold";
            margin-bottom: 0;
            @media (max-width: 576px) {
                font-size: 18px;
            }
        }
        p {
            font-size: 22px;
            text-align: center;
            max-width: 560px;
            margin: auto;
            margin-bottom: 30px;
            @media (max-width: 576px) {
                font-size: 14px;
            }
        }
    }
}
