.figure-detail {
    .tinetti-body {
        .Initiation-table {
            .chart-main {
                .chart2 {
                    background-color: #c64028 !important;
                }
            }
            .chart-main2 {
                .chart-data {
                    align-items: flex-end;
                    .chart {
                        &:nth-child(2) {
                            height: 12px;
                        }
                        &:nth-child(3),
                        &:nth-child(4) {
                            height: 14px;
                        }
                    }
                }
            }
            .segment {
                .chart-data {
                    align-items: flex-end;
                    .chart {
                        &:nth-child(3),
                        &:nth-child(4) {
                            height: 14px;
                        }
                    }
                }
            }
        }
        .symmetric {
            p {
                width: 267px !important;
                text-align: start;
                color: #483a46;
                font-family: "commonsRegular" !important;
                font-size: 18px;
                margin-bottom: 0;
            }
            .comple {
                color: #000;
                font-family: "Inter", sans-serif !important;
                font-size: 16px;
            }
        }
    }
}
.video-modal {
    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        padding: 0 14px;
        .modal-content {
            iframe {
                width: 100%;
                height: 282px;
            }
            .close-btn {
                position: absolute;
                background: #fff;
                border: 0;
                border-radius: 50%;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                right: 10px;
                top: 10px;
            }
        }
    }
}
