.main-login {
    max-width: 820px;
    margin: auto;
    background-image: url("../../Assets/Images/vector.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding-bottom: 49px;
    .movement {
        padding-top: 103px;
        margin-bottom: 81px;
        @media (max-width: 576px) {
            padding-top: 34px;
        }
        p {
            text-align: center;
            font-family: "commonsMedium";
            margin-top: -34px;
            @media (max-width: 576px) {
                margin-top: -24px;
            }
        }
    }
    img {
        width: auto;
        @media (max-width: 576px) {
            width: 100%;
        }
    }
    .btn-data {
        display: flex;
        flex-direction: column;
        gap: 23px;
        margin-top: 23px;
        align-items: center;
        button {
            width: 400px;
            height: 125px;
            border-radius: 99px;
            font-size: 30px;
            border: 0;
            font-family: "commonsBold";
            @media (max-width: 576px) {
                width: 250px;
                height: 80px;
                font-size: 24px;
            }
        }
        .signup-btn {
            background: #8e576f;
            color: #fff;
        }
        .login-btn {
            background: #f2f1f6;
            color: #483a46;
            border: 1.272px solid #d9d9d9;
        }
    }
    .terms-poicy {
        font-size: 16px;
        font-family: "commonsRegular";
        max-width: 320px;
        margin: auto;
        margin-top: 60px;
        text-align: center;
        @media (max-width: 576px) {
            max-width: 270px;
        }
        span {
            font-family: "commonsBold";
        }
    }
}
