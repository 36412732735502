.text-header {
    position: relative;
    padding: 18px 32px;
    border-bottom: 1px solid #d9d9d9;
    text-align: start !important;
    @media (max-width: 576px) {
        padding: 18px 10px;
    }
    .patient-btn {
        font-size: 17px;
        font-family: "commonsBold";
        width: 130px;
        height: 51px;
        background: #f2f1f6;
        border: 1px solid #d9d9d9;
        border-radius: 30px;
        color: #483a46;
        @media (max-width: 768px) {
            width: 90px;
            height: 44px;
        }
    }
    .logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        @media (max-width: 576px) {
            left: initial;
            right: -55px;
        }
        h1 {
            font-size: 24px;
            color: #483a46;
            font-family: "commonsBold";
            margin-bottom: 0;
            @media (max-width: 576px) {
                font-size: 20px;
            }
        }
    }
}
