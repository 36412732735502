@font-face {
  font-family: "commonsThin";
  src: url("https://fonts.cdnfonts.com/s/51357/TT Commons Thin.woff");
}
@font-face {
  font-family: "commonsRegular";
  src: url("https://fonts.cdnfonts.com/s/51357/TT Commons Regular.woff");
}
@font-face {
  font-family: "commonsItalic";
  src: url("https://fonts.cdnfonts.com/s/51357/TT Commons Italic.woff");
}
@font-face {
  font-family: "commonsMedium";
  src: url("https://fonts.cdnfonts.com/s/51357/TT Commons Medium.woff");
}
@font-face {
  font-family: "commonsBold";
  src: url("https://fonts.cdnfonts.com/s/51357/TT Commons Bold.woff");
}
@font-face {
  font-family: "commonsExtraBold";
  src: url("https://fonts.cdnfonts.com/s/51357/TT Commons ExtraBold.woff");
}
