.patient-list {
    max-width: 820px;
    margin: auto;
    background-color: hsl(252, 22%, 95%);
    .main-header {
        background-color: #fff;
        .logo {
            @media (max-width: 576px) {
                left: 52%;
            }
        }
        .new-btn {
            background: #f2f1f6;
            border: 1px solid #d9d9d9;
            color: #483a46;
            width: 126px;
            @media (max-width: 425px) {
                width: 76px;
                line-height: 16px;
                padding: 0 20px;
            }
        }
    }
    .profile-guide {
        padding: 50px;
        padding-top: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #d9d9d9;
        @media (max-width: 576px) {
            padding: 50px 14px 26px;
        }
        @media (max-width: 425px) {
            display: block;
        }
        .new-profile {
            h1 {
                color: #483a46;
                font-size: 40px;
                font-family: "commonsBold";
                margin-bottom: 12px;
                @media (max-width: 576px) {
                    font-size: 32px;
                    margin-bottom: 4px;
                }
            }
            p {
                font-size: 16px;
                font-family: "commonsRegular";
                color: #483a46;
                margin-bottom: 0;
                span {
                    font-family: "commonsBold";
                }
            }
        }
        button {
            background: #8e576f;
            border-radius: 30px;
            width: 173px;
            height: 51px;
            color: #ffffff;
            font-family: "commonsBold";
            border: 0;
            @media (max-width: 425px) {
                margin-top: 30px;
            }
        }
    }
    .patient-card {
        width: 100%;
        padding: 44px 50px 120px 50px;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        row-gap: 36px;
        @media (max-width: 768px) {
            padding: 44px 25px 120px 25px;
        }
        @media (max-width: 576px) {
            padding: 44px 14px 50px 14px;
        }
        @media (max-width: 425px) {
            row-gap: 26px;
            flex-direction: column;
        }
        .patient-data {
            position: relative;
            width: calc(33.33% - 8px);
            @media (max-width: 768px) {
                width: calc(50% - 8px);
            }
            @media (max-width: 425px) {
                width: 100%;
            }
            .patient-list {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                position: relative;
                z-index: 1;
                padding: 25px 28px 30px 27px;
                min-height: 292px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media (max-width: 576px) {
                    padding: 16px 18px 20px 18px;
                    min-height: 222px;
                }
                .list-data {
                    background: #ffffff;
                    span {
                        font-size: 16px;
                        font-family: "commonsBold";
                    }
                    h1 {
                        color: #483a46;
                        font-size: 24px;
                        font-family: "commonsBold";
                        margin-bottom: 0;
                        line-height: 30px;
                        max-width: 140px;
                        @media (max-width: 576px) {
                            font-size: 22px;
                            line-height: 22px;
                        }
                    }
                }
                button {
                    color: #483a46;
                    font-size: 17px;
                    font-family: "commonsBold";
                    background: #f2f1f6;
                    border: 1px solid #d9d9d9;
                    border-radius: 30px;
                    width: 177px;
                    height: 51px;
                    @media (max-width: 992px) {
                        width: 137px;
                    }
                }
            }
            .patients {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                height: 50px;
                position: absolute;
                width: 96%;
                left: 50%;
                bottom: -8px;
                transform: translateX(-50%);
            }
        }
    }
}
