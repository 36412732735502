.assessment-main {
    max-width: 820px;
    margin: auto;
    background-color: #f2f1f6;
    .report-main {
        background: #483a46;
        color: #fff;
        .profile-assessment {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 32px 0;
            @media (max-width: 576px) {
                padding: 20px 14px 0;
            }
            .home-btn {
                background-color: transparent;
                border: 0;
                font-family: "commonsBold";
                font-size: 20px;
                display: flex;
                align-items: center;
                color: #fff;
                @media (max-width: 576px) {
                    font-size: 14px;
                }
                svg {
                    font-size: 30px;
                    @media (max-width: 576px) {
                        font-size: 24px;
                    }
                }
            }
            .report-btn {
                background: #fa7961;
                border-radius: 6px;
                width: 203px;
                height: 39px;
                font-size: 16px;
                font-family: "commonsBold";
                border: 0;
                color: #fff;
                @media (max-width: 576px) {
                    width: 173px;
                    font-size: 14px;
                }
            }
        }
        .profile-guide {
            padding: 50px;
            padding-top: 90px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #d9d9d9;
            @media (max-width: 576px) {
                padding: 50px 14px 26px;
            }
            @media (max-width: 425px) {
                display: block;
            }
            .new-profile {
                h1 {
                    color: #fff;
                    font-size: 40px;
                    font-family: "commonsBold";
                    margin-bottom: 12px;
                    @media (max-width: 576px) {
                        font-size: 32px;
                        margin-bottom: 4px;
                    }
                }
                p {
                    font-size: 16px;
                    font-family: "commonsRegular";
                    color: #fff;
                    margin-bottom: 0;
                    span {
                        font-family: "commonsBold";
                    }
                }
            }
            .details {
                p {
                    font-size: 16px;
                    font-family: "commonsBold";
                    color: #f2f1f6;
                    margin-bottom: 0;
                }
                .venue-data {
                    margin-top: 11px;
                    p {
                        margin-bottom: 2px;
                        span {
                            font-family: "commonsRegular";
                        }
                    }
                }
            }
        }
    }
    .patient-card {
        width: 100%;
        padding: 44px 50px 120px 50px;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        row-gap: 36px;
        @media (max-width: 768px) {
            padding: 44px 25px 120px 25px;
        }
        @media (max-width: 576px) {
            padding: 44px 14px 50px 14px;
        }
        @media (max-width: 425px) {
            row-gap: 26px;
            flex-direction: column;
        }
        .patient-data {
            position: relative;
            width: calc(50% - 8px);
            @media (max-width: 576px) {
                width: 100%;
            }
            .patient-list {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                position: relative;
                z-index: 1;
                min-height: 292px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media (max-width: 992px) {
                    min-height: 332px;
                }
                @media (max-width: 576px) {
                    min-height: 222px;
                }
                .report-data {
                    padding: 20px 18px 0 18px;
                    .list-data {
                        background: #ffffff;
                        display: flex;
                        justify-content: space-between;
                        span {
                            font-size: 16px;
                            font-family: "commonsBold";
                        }
                        h1 {
                            color: #483a46;
                            font-size: 24px;
                            font-family: "commonsBold";
                            margin-bottom: 0;
                            line-height: 50px;
                            @media (max-width: 576px) {
                                font-size: 22px;
                                line-height: 22px;
                            }
                        }
                    }
                    .session-main {
                        margin-top: 33px;
                        display: flex;
                        align-items: center;
                        gap: 14px;
                        .session-data {
                            h1 {
                                font-size: 70px;
                                font-family: "commonsBold";
                                color: #483a46;
                                margin-bottom: 0;
                                @media (max-width: 768px) {
                                    font-size: 48px;
                                }
                                span {
                                    font-size: 40px;
                                    @media (max-width: 768px) {
                                        font-size: 28px;
                                    }
                                }
                            }
                            .mph-data {
                                span {
                                    font-size: 16px;
                                }
                            }
                            p {
                                color: #483a46;
                                margin-bottom: 9px;
                                font-size: 15px;
                                @media (max-width: 768px) {
                                    font-size: 12px;
                                }
                                span {
                                    font-weight: bold;
                                }
                            }
                            .sess-main {
                                display: flex;
                                align-items: flex-end;
                                svg {
                                    color: #c64028;
                                    font-size: 28px;
                                    margin-bottom: 10px;
                                    margin-left: 6px;
                                }
                                .down-arrow {
                                    color: #3d963e;
                                }
                            }
                        }
                        .report-chart {
                            p {
                                font-size: 14px;
                                color: #483a46;
                                font-family: "commonsRegular";
                                margin-top: 29px;
                            }
                        }
                    }
                    p {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                }
                .report-view {
                    border-top: 1px solid #d9d9d9;
                    padding: 20px 0;
                    button {
                        color: #483a46;
                        font-size: 17px;
                        font-family: "commonsBold";
                        background: #f2f1f6;
                        border: 1px solid #d9d9d9;
                        border-radius: 30px;
                        width: 177px;
                        height: 51px;
                        @media (max-width: 992px) {
                            width: 137px;
                        }
                    }
                }
            }
            .patients {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                height: 50px;
                position: absolute;
                width: 96%;
                left: 50%;
                bottom: -8px;
                transform: translateX(-50%);
            }
        }
    }
}
